<template>
  <div id="unsuccessful-deliveries-table">
    <Deliveries :isOpen.sync="showDeliveryModal" :delivery="editRowData" @save="getData" />
    <Table
      :dataPreProcessor="deliveriesDataPreProcessor"
      :focusable="false"
      :loading.sync="oLoading.table"
      :perPage="40"
      :show-detail-icon="false"
      @setTotalItems="(data) => $emit('setTotalItems', data)"
      apiUrl="delivery_manifest/unsuccessful_deliveries"
      class="unsuccessful-table"
      infiniteScroll
      ref="table"
      :updateInterval="70"
    >
      <div class="flex f-jsb f-ai options-header">
        <div class="flex icons">
          <DeliveryStatusUI type="rush" component="simple-text" />
          <DeliveryStatusUI type="priority" component="simple-text" />
          <DeliveryStatusUI type="refrigerated" component="simple-text" />
          <DeliveryStatusUI type="leave-at-door" component="simple-text" />
          <DeliveryStatusUI type="collect" component="simple-text" />
        </div>
      </div>
      <b-table-column :label="$t('global.order')" width="240" v-slot="props" :td-attrs="tdAttrs">
        <div class="flex f-wrap cell-order icons-table is-align-items-center">
          <DeliveryIcons
            :status="{
              rush: props.row.rush,
              priority: props.row.priority,
              refrigerated: props.row.refrigeration_needed,
              leaveAtDoor: props.row.leave_at_door,
              collect: props.row.is_collect_event
            }"
          />
          <span> {{ props.row.order_number }} </span>
          <br />
          <CopaymentTag :value="props.row.copayment_amount" />
          <div class="failed-status">
            <span> {{ failedStatus(props.row.delivery_event_status_id) }}</span>
          </div>
        </div>
      </b-table-column>
      <b-table-column :label="$t('global.date')" v-slot="props" width="130" :td-attrs="tdAttrs">
        <DateTime class="f-jc" :date="props.row.delivery_date" format="MM/DD/Y" />
        <ConstraintDatesViewer
          isTag
          :offset="props.row.offset_tz"
          :startTime="props.row.delivery_start_time"
          :endTime="props.row.delivery_end_time"
        />
      </b-table-column>
      <b-table-column :label="$tc('participant.label')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.participant_full_name }}
      </b-table-column>
      <b-table-column :label="$t('schedule.delivery')" v-slot="props" :td-attrs="tdAttrs">
        <div class="deliveryAddress">
          <strong>{{ props.row.participant_full_address || '-' }}</strong>
          <p>{{ props.row.cdp_full_address || '-' }}</p>
        </div>
      </b-table-column>
      <b-table-column v-slot="props" :td-attrs="tdAttrs">
        <div class="flex">
          <Link
            class="ml-auto"
            :label="$t('button.showMap')"
            icon="map-marker-radius"
            @click="() => onShowMarkerOnMap(props.row)"
            :permission="Permission.showOnMap"
            autoLoading
          />
          <Link
            :label="$t('schedule.assignDelivery')"
            @click="() => onAssignDelivery(props.row)"
            class="dropdown"
            icon="arrow-right-bold-box"
            :disabled="
              oDisabled ||
              !manifest ||
              !manifest.id ||
              oLoading.rowId != 0 ||
              oLoading.delete ||
              props.row.canceled
            "
            :loading="oLoading.rowId == props.row.id"
            :permission="Permission.assign"
          />
          <Dropdown
            v-if="moreActionsDropdown.length > 0"
            :label="$t('global.moreActions')"
            :maxHeight="200"
            :loading="props.row.id == cancelingId"
            position="is-bottom-left"
            icon="dots-horizontal"
            :showLabel="false"
            @click="(value) => onClick(value, props.row)"
            :disabled="oLoading.rowId != 0 || oLoading.delete"
            :items="moreActionsDropdown(props.row.canceled)"
          />
        </div>
      </b-table-column>
    </Table>
  </div>
</template>

<script>
import { Permissions } from '@/utils/Secure';
import { toast, confirm } from '@/utils/dialog';
import { Deliveries } from '@/views/Fragments';
import {
  CopaymentTag,
  DateTime,
  Dropdown,
  Link,
  Table,
  ConstraintDatesViewer,
  DeliveryStatusUI,
  DeliveryIcons
} from '@/components';

export default {
  components: {
    DateTime,
    Deliveries,
    Dropdown,
    Link,
    Table,
    ConstraintDatesViewer,
    DeliveryStatusUI,
    DeliveryIcons,
    CopaymentTag
  },
  created() {
    this.unsubscribe = this.$store.subscribe(
      ({ type }, { schedule: { currentManifest }, dispatch: { manifest } }) => {
        if (type == 'schedule/currentManifest') {
          this.manifest = currentManifest;
        }
        if (this.dispatchView && type == 'dispatch/manifest') {
          this.manifest = manifest;
        }
      }
    );
  },
  destroyed() {
    this.onShowMarkerOnMap();
    this.unsubscribe();
  },
  data() {
    return {
      cancelingId: null,
      editRowData: null,
      manifest: null,
      oDisabled: false,
      oLoading: { delete: false, table: this.loading, rowId: 0 },
      showDeliveryModal: false,
      unsubscribe: null
    };
  },
  computed: {
    bTable() {
      return this.$refs?.table?.bTable;
    },
    Permission() {
      return Permissions.Scheduling;
    }
  },
  methods: {
    async getData(data) {
      await this.$refs.table.getData(data);
    },
    async onAssignDelivery(delivery, isOverride) {
      const currentDeliverys = {
        unattended_delivery_id: delivery.unattended_delivery_id,
        delivery_event_type_id: delivery.is_collect_event === 1 ? 8 : 3,
        order_number: delivery.order_number
      };

      this.oLoading.rowId = delivery.id;

      try {
        const deliveryID = delivery.id;
        const deliveryManifest = delivery.delivery_manifest_id;

        await this.Api.patch(
          `delivery_manifest/${deliveryManifest}/delivery_events/${deliveryID}/set_available_to_delivery`
        );

        const url = `delivery_manifest/${this.manifest.id}/delivery_events${
          isOverride ? '_override' : ''
        }`;

        const { data } = await this.Api.post(url, currentDeliverys);
        this.$refs.table.removeRow({ key: 'id', value: currentDeliverys });
        this.$store.commit('schedule/assignedDelivery', data);

        toast('success', this.$t('schedule.messages.assigned'), 5000);
        this.getData();
      } catch (error) {
        if (error?.data?.status_code === 'OVERTIME_WORK') {
          confirm({
            message: this.confirmMessage(
              this.$t('schedule.confirm.overtime'),
              delivery,
              error?.data?.text[0]
            ),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('confirm.yes'),
            onConfirm: async () => {
              this.onAssignDelivery(delivery, true);
            }
          });
        }
        if (error?.data?.status_code === 'cdm001') {
          confirm({
            message: this.confirmMessage(this.$t('schedule.confirm.manifestRunning'), delivery),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('confirm.yes'),
            onConfirm: async () => {
              this.onAssignDelivery(delivery, false);
            }
          });
        }
      }
      this.oLoading.rowId = 0;
    },
    async onDeleteDelivery(delivery) {
      confirm({
        message: this.confirmMessage(this.$t('confirms.deleteDelivery'), delivery),
        cancelText: this.$t('confirm.no'),
        confirmText: this.$t('confirm.yes'),
        onConfirm: async () => {
          try {
            await this.Api.delete(`delivery_manifest/unattended_deliveries/${delivery.id}`);
            this.$refs.table.removeRow({ key: 'id', value: delivery.id });
            toast('success', this.$t('schedule.messages.deleted'), 5000);
          } catch (error) {
            console.error(error);
          }
        }
      });
    },
    failedStatus(id) {
      if (id === 6) return this.$t('schedule.rejected');
      else if ([7, 16].includes(id)) return this.$t('schedule.cancelled');
      else if ([11, 17].includes(id)) return this.$t('schedule.failed');
    },
    moreActionsDropdown(isCanceled) {
      const actions = [
        {
          value: this.$t('schedule.deleteDelivery'),
          event: 'onDeleteDelivery',
          permission: this.Permission.delete
        }
      ];
      if (!isCanceled) {
        return [
          {
            value: this.$t('schedule.editDelivery'),
            event: 'onEditDelivery',
            permission: this.Permission.update
          },
          ...actions
        ];
      }
      return actions;
    },
    deliveriesDataPreProcessor(deliveries) {
      if (deliveries[0]?._source) return deliveries.map((RD) => RD._source);
      return deliveries;
    },
    isReadOnly({ delivery_manifest_status_id: status_id }) {
      return !this.dispatchView && [3, 4].includes(status_id);
    },
    onClick(value, data) {
      this[value.event](data);
    },
    confirmMessage(baseMessage, delivery, isMultiple = false, error) {
      let message = `<div>${baseMessage}</div>`;
      if (delivery && !isMultiple) {
        const { order_number, participant_full_name, participant_full_address } = delivery;
        message = `<table>
            <tr><td><strong>Order:</strong></td><td>${order_number || '-'}</td></tr>
            <tr><td><strong class="mr-2">Participant: </strong></td><td>${participant_full_name}<td></td></tr>
            <tr><td><strong>Address:</strong></td><td>${participant_full_address}</td></tr>
            </table>`;
      } else if (isMultiple) {
        message = `<div>
          <div class="mb-4" >${
            error ? this.$t('schedule.multipleMessageOvertime') : baseMessage
          }</div>
          ${error ? `<div>${error}<div>` : ''}
          <div class="mt-4" >${this.$t('schedule.multipleProceed')}<div>
        </div>`;
      } else {
        message = `
        <h3><strong>Warning</strong></h3>
        <br>
        <p>${this.$t('schedule.cancelOption')}</p>`;
      }
      return message;
    },
    obtainDeliverysForType(delivery) {
      let collectsDeliverys = [];
      let deliverys = [];

      let allDeliverys = delivery.map((row) => {
        row.is_collect_event === 1
          ? (collectsDeliverys = [...collectsDeliverys, row.id])
          : (deliverys = [...deliverys, row.id]);

        return row.id;
      });

      return { deliverys, collectsDeliverys, allDeliverys };
    },
    onShowMarkerOnMap(delivery) {
      const payload = delivery
        ? {
            lat: delivery?.participant_latitude,
            lng: delivery?.participant_longitude,
            participant_full_address: delivery?.participant_full_address,
            participant_full_name: delivery?.participant_full_name,
            order_number: delivery?.tracking_number
          }
        : undefined;
      this.$store.dispatch('map/showMarker', payload);
    },
    onEditDelivery(row) {
      this.editRowData = row;
      this.showDeliveryModal = true;
    },
    columnClassByRow(row = {}) {
      let classes = [];
      if (!row.is_active) classes.push('inactive');
      if (row.isOpen) classes.push('isOpen');
      return classes.join(' ');
    },
    tdAttrs(row) {
      return { class: this.columnClassByRow(row) };
    },
    toggleDetails(row) {
      row.isOpen = !row.isOpen;
      this.bTable.toggleDetails(row);
    }
  },
  watch: {
    showDeliveryModal(value) {
      if (!value) this.editRowData = {};
    },
    disabled(value) {
      this.oDisabled = value;
    },
    loading(value) {
      this.oLoading.table = value;
    },
    'oLoading.table'(value) {
      this.$emit('update:loading', value);
    },
    manifest(value) {
      if (value) this.oDisabled = this.isReadOnly(value);
    }
  },
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    params: { type: Object, default: () => {} },
    dispatchView: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#unsuccessful-deliveries-table
  height: 100%
  border-radius: $br-md
  .table-container
    min-height: calc(100% - 120px)
.failed-status
  background: $red-200
  border-radius: 20px
  text-align: center
  color: $red-700
  padding: 0.2rem 0.4rem
  margin-left: 0.5rem
.cell
  &-icons
    display: flex
    align-items: flex-start
    ::v-deep
      .icon
        height: 25px
  &-order
    word-break: break-word
    width: 100%
.unsuccessful-table
  .icons
    .simple-delivery ::v-deep
      i:before
        transform: translate(-55%, -17px) !important
    .simple-delivery:nth-child(3), .simple-delivery:nth-child(5)
      ::v-deep
        i::before
          transform: translate(-55%, -12px) !important
  .options-header
    margin: 0rem 0 1rem 0
    height: 40px
    .refrigerated
      display: flex
      align-items: center
      ::v-deep
        .refrigerated-text
          font-size: 14px
        .refrigerated-icon
          width: 32px
          font-size: 16.67px
          color: $blue-700
  .deliveryAddress
    font-size: $f-sm
  .icons-table
    ::v-deep
      .icons
        .simple-delivery .icon
          i:before
            transform: translate(-48%, -17px) !important
        .simple-delivery__collect .icon,  .simple-delivery__refrigerated .icon
          i:before
            transform: translate(-48%, -13px) !important
  ::v-deep
    th
      z-index: 39!important
    tr
      .isOpen:first-child,
      .isOpen:last-child
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
    .table-wrapper
      max-height: calc(100vh - 320px)
@media only screen and (max-width: $bp-xl)
  #unsuccessful-deliveries-table
    margin-top: 0
    ::v-deep .table-title
      display: none
  .unsuccessful-table
    ::v-deep
      .table-wrapper
        max-height: calc(100vh - 380px)
.w-130
  width: 130px
.w-140
  width: 140px
.w-250
  width: 250px
</style>

<style lang="sass">
.dark
  .unsuccessful-table
    strong
      color: $main-background
</style>
