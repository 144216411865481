<template>
  <div>
    <Table
      :focusable="false"
      :loading.sync="oLoading.table"
      :params="aParams"
      :show-detail-icon="false"
      apiUrl="delivery_manifest/unsuccesfully_events"
      class="delivery-issues-table"
      infiniteScroll
      ref="table"
    >
      <b-table-column :label="$t('global.order')" width="170" v-slot="props" :td-attrs="tdAttrs">
        <div class="flex">
          <DeliveryIcons
            isTag
            :status="{
              collect: props.row.delivery_event_type_id === 8,
              leaveAtDoor: props.row.leave_at_door,
              priority: props.row.priority,
              refrigerated: props.row.refrigeration_needed,
              rush: props.row.rush
            }"
          />
        </div>
      </b-table-column>
      <b-table-column :label="$t('global.date')" v-slot="props" :td-attrs="tdAttrs">
        <DateTime :date="props.row.delivery_date" format="MM/DD/Y" />
        <ConstraintDatesViewer
          isTag
          :endTime="props.row.delivery_end_time"
          :offset="props.row.time_offset"
          :startTime="props.row.delivery_start_time"
          class="text-wrap"
        />
      </b-table-column>
      <b-table-column :label="$tc('participant.label')" v-slot="props" :td-attrs="tdAttrs">
        <div>
          {{ props.row.participant_full_name }}
        </div>
        {{ props.row.client_code }}
      </b-table-column>
      <b-table-column :label="$t('schedule.delivery')" v-slot="props" :td-attrs="tdAttrs">
        <p>{{ props.row.destination_full_address || '-' }}</p>
        <p>
          {{ $t('menu.cdp') }}: <span>{{ props.row.cdp_full_address || '-' }}</span>
        </p>
      </b-table-column>
      <b-table-column :label="$t('employee.name.driver')" v-slot="props" :td-attrs="tdAttrs">
        <div>
          {{ props.row.employee_full_name }}
        </div>
      </b-table-column>
      <b-table-column :label="$t('dispatch.issue')" v-slot="props" :td-attrs="tdAttrs">
        <p v-if="[11, 7, 6].includes(props.row.delivery_event_status_id)">
          {{ $t('dispatch.notReturned') }}
        </p>
        <p v-else>{{ $t('dispatch.notDelivered') }}</p>
      </b-table-column>
      <b-table-column :td-attrs="tdAttrs" v-slot="props">
        <div class="flex">
          <Link
            @click="onDeliveryFix(props.row)"
            class="ml-5 mr-5"
            icon="tools"
            :label="$t('global.fix')"
            v-if="hasPermissionFix"
          />
        </div>
      </b-table-column>
    </Table>
  </div>
</template>

<script>
import { DateTime, Link, Table, ConstraintDatesViewer, DeliveryIcons } from '@/components';
import signature from './encodedImage';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    DateTime,
    Link,
    Table,
    ConstraintDatesViewer,
    DeliveryIcons
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { schedule: { currentManifest } }) => {
      if (type == 'schedule/currentManifest') {
        this.manifest = currentManifest;
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      eventId: null,
      manifest: null,
      manifestId: null,
      oLoading: { table: this.loading, rowId: 0, assign: false },
      unsubscribe: null,
      isReturned: null
    };
  },
  computed: {
    bTable() {
      return this.$refs?.table?.bTable;
    },
    aParams() {
      let aParams = [];
      Object.keys(this.params).map((key) => {
        let value = this.params[key];
        if (value) aParams.push(`${key}=${value}`);
      });
      return aParams;
    },
    hasPermissionFix() {
      let isAllowed = false;
      if (Permissions.Dispatch.resultIssues?.length) {
        isAllowed = Permissions.Dispatch.resultIssues.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(Permissions.Dispatch.resultIssues);
    }
  },
  methods: {
    async getData() {
      await this.$refs.table.getData({ clear: true });
    },
    columnClassByRow(row = {}) {
      let classes = [];
      if (!row.is_active) classes.push('inactive');
      if (row.isOpen) classes.push('isOpen');
      return classes.join(' ');
    },
    tdAttrs(row) {
      return { class: this.columnClassByRow(row) };
    },
    async onDeliveryFix(delivery) {
      this.oLoading.table = true;
      if ([11, 7, 6].includes(delivery.delivery_event_status_id)) {
        this.isReturned = true;
      } else {
        this.isReturned = false;
      }
      try {
        const body = this.isReturned ? { signature } : '';
        await this.Api.put(
          `/delivery_manifest/${delivery.delivery_manifest_id}/delivery_events/${delivery.id}/${
            this.isReturned ? 'fix_as_returned' : 'fix_as_failed'
          }`,
          body
        );
        this.$toast('success', this.$t('messages.saved'), 5000);
      } catch (error) {
        console.log(error);
      }
      this.oLoading.table = false;
    }
  },
  watch: {
    loading(value) {
      this.oLoading.table = value;
    },
    'oLoading.table'(value) {
      this.$emit('update:loading', value);
    }
  },
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    params: { type: Object, default: () => {} }
  }
};
</script>
<style lang="sass" scoped>
#unattended-deliveries-table
  margin-top: -55px
  border-radius: $br-md
  .table-container
    min-height: calc(100% - 120px)
.delivery-issues-table
  ::v-deep
    th
      z-index: 39!important
    tr
      .isOpen:first-child,
      .isOpen:last-child
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
    .table-wrapper
      height: calc(100vh - 250px)
@media only screen and (max-width: $bp-xl)
  #unattended-deliveries-table
    margin-top: 0
    ::v-deep .table-title
      display: none
.w-130
  width: 130px
.w-140
  width: 140px
.w-250
  width: 250px
</style>
