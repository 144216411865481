<template>
  <div class="delivery-event">
    <div class="cdp-indicator" :style="indicatorCssColor" />
    <div class="delivery-event-heading flex f-jsb" :class="classes">
      <div class="flex pr-2 w-100">
        <div class="flex f-jsa f-ai f-column icon-panel">
          <b-icon
            v-if="loading.pickup === 'above'"
            icon="loading"
            class="pickup-loading"
            custom-class="mdi-spin"
          />
          <Tooltip
            v-else-if="allowAddPickup"
            :active="!loadingPickup"
            class="add-above"
            :label="$t('manifest.addPickupAbove')"
          >
            <ArrowPlusUp @click="onAddPickup('above')" :disabled="!!loadingPickup" />
          </Tooltip>
          <DeliveryStatusSignUI
            class="delivery-status-sign ml-3 mr-1"
            :delivery="delivery"
            markerShowNumbers
            @status="(data) => (status = data)"
            isMapMarker
            :isCollect="isCollect"
          />
          <b-icon
            v-if="loading.pickup === 'bellow'"
            icon="loading"
            class="pickup-loading"
            custom-class="mdi-spin"
          />
          <Tooltip
            v-else-if="allowAddPickup"
            :active="!loadingPickup"
            class="add-bellow"
            :label="$t('manifest.addPickupBellow')"
          >
            <ArrowPlusDown @click="onAddPickup('bellow')" :disabled="!!loadingPickup" />
          </Tooltip>
        </div>
        <div class="flex f-jc f-column pt-1 pb-1 w-100">
          <div class="flex f-jsb w-100 f-ai">
            <p>
              <span class="title-type">
                {{ status.participant_name || status.name }}
              </span>
              <strong v-if="isPickup"> - {{ delivery.close_door_pharmacy_name }} </strong>
            </p>
            <DeliveryIcons
              v-if="isDelivery"
              class="ml-2 icons-deliverys"
              isIconTag
              :status="{
                rush: delivery.rush,
                priority: this.delivery.priority,
                refrigerated: delivery.refrigeration_needed,
                leaveAtDoor: delivery.leave_at_door,
                collect: isCollect
              }"
            />
          </div>

          <div class="">
            <div v-if="isDelivery">
              <p class="title-cdp flex f-ai">
                <b-icon icon="arrow-up-bold-box" class="upDownBoxIcon" custom-class="icon-18" />
                {{ delivery.close_door_pharmacy_name }}
              </p>
              <p v-if="showAddress" class="title-address flex f-ai">
                <b-icon icon="arrow-down-bold-box" class="upDownBoxIcon" custom-class="icon-18" />
                {{ delivery.destination_full_address || '-' }}
              </p>

              <div class="flex f-jsb">
                <div class="copayment">
                  <CopaymentTag :value="delivery.copayment_amount" />
                </div>
                <div class="flex f-jsb">
                  <CopyTextToClipBoard
                    class="order-number"
                    v-if="isDelivery"
                    :copyToText="delivery.order_number"
                    :text="`#${delivery.order_number}`"
                  />
                  <div class="flex">
                    <Link
                      :label="$t('button.showMap')"
                      :loading="loading.showOnMap"
                      @click.stop="onShowOnMap"
                      class="show-on-map"
                      icon="map-marker-radius"
                    />
                    <Link
                      :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                      :label="isOpen ? $t('global.lessDetails') : $t('global.moreDetails')"
                      @click="isOpen = !isOpen"
                      class="dropdown"
                    />
                  </div>
                </div>
              </div>
            </div>
            <EtaTiming
              v-if="!isDelivery && delivery.eta"
              class="eta"
              :eta="delivery.eta"
              :offset="delivery.time_offset"
              useIcon
            />
          </div>
        </div>
        <div
          v-if="isPickup"
          class="flex f-ai removePickup"
          :class="isRemovePickupLoading ? 'isLoading' : ''"
        >
          <Link
            :label="$t('manifest.removePickup')"
            :disabled="!!loadingRemovePickup"
            :loading="isRemovePickupLoading"
            @click="onRemovePickup"
            icon="delete-outline"
          />
        </div>
      </div>
    </div>
    <b-collapse class="delivery-event-collapse" animation="slide" v-model="isOpen">
      <div class="delivery-event-body pt-2">
        <div class="flex f-jsb">
          <div class="flex f-jsb">
            <DeliveryIcons
              v-if="isDelivery"
              class="icons-deliverys"
              isTag
              :status="{
                rush: delivery.rush,
                priority: delivery.priority,
                refrigerated: delivery.refrigeration_needed,
                leaveAtDoor: delivery.leave_at_door,
                collect: isCollect
              }"
            >
              <DeliveryStatusSignUI :delivery="delivery" class="mb-3" :isCollect="isCollect" />
            </DeliveryIcons>
          </div>

          <div class="flex f-jce is-align-items-center ml-auto">
            <template v-if="loading.dropdown">
              <Dropdown
                class="mb-auto"
                :label="$t('global.options')"
                :maxHeight="200"
                position="is-bottom-left"
                icon="dots-horizontal"
                :items="optionsDropdown"
                :showLabel="false"
                @click="(value) => emitDropdownEvent(value)"
              />
            </template>
            <b-icon v-else icon="loading" custom-class="mdi-spin" />
          </div>
        </div>
        <div v-if="isDelivery" class="flex f-column body-header">
          <div class="w-100 mr-2 flex f-jsb">
            <MessageBadge
              class="mb-0"
              type="is-advice"
              v-if="delivery.delivery_start_time || delivery.delivery_end_time"
            >
              <ConstraintDatesViewer
                v-if="delivery.delivery_start_time || delivery.delivery_end_time"
                :startTime="delivery.delivery_start_time"
                :endTime="delivery.delivery_end_time"
                :offset="delivery.time_offset"
              />
            </MessageBadge>
          </div>
        </div>
        <div v-if="[6, 7, 11, 16, 17].includes(delivery.delivery_event_status_id)">
          <MessageBadge
            type="is-danger"
            :message="
              delivery.non_delivery_reason_id !== 4
                ? delivery.non_delivery_reason_description
                : delivery.other_non_delivery_reason_description
            "
          />
        </div>
        <EtaTiming
          class="mb-2"
          v-if="delivery.eta"
          :eta="delivery.eta"
          :offset="delivery.time_offset"
          useIcon
        />
        <p v-if="delivery.estimated_distance" class="mb-2">
          {{ $t('schedule.manifest.distance') }}: {{ delivery.estimated_distance || '-' }}
        </p>
        <p class="mb-2">
          {{ $t('address.address', [$tc('')]) }}: {{ delivery.destination_full_address || '-' }}
        </p>
        <p v-if="delivery.gate_code">
          {{ $t('global.gateCode') }}: {{ delivery.gate_code || '-' }}
        </p>
        <p v-if="delivery.comments">
          {{ $tc('global.comment', 2) }}: {{ delivery.comments || '-' }}
        </p>

        <div class="flex f-column">
          <div v-if="delivery.participant_phone_number" class="phone-info flex">
            <Tooltip
              :label="
                delivery.is_phone_number_delivery_event
                  ? $t('shcedule.phoneAssociatedToEvent')
                  : $t('global.defaultPhone')
              "
            >
              <b-icon
                :icon="delivery.is_phone_number_delivery_event ? 'check-decagram' : 'star'"
                :class="delivery.is_phone_number_delivery_event ? 'mobile-icon' : 'land-icon'"
              />
            </Tooltip>
            <p class="ml-2">
              {{ $tc('contact.landline') }}: {{ delivery.participant_phone_number }}
            </p>
          </div>
          <div v-if="delivery.participant_mobile_number" class="phone-info flex">
            <Tooltip
              :label="
                delivery.is_mobile_phone_number_delivery_event
                  ? $t('shcedule.phoneAssociatedToEvent')
                  : $t('global.defaultPhone')
              "
            >
              <b-icon
                :icon="
                  delivery.is_mobile_phone_number_unattended_delivery ? 'check-decagram' : 'star'
                "
                :class="
                  delivery.is_mobile_phone_number_unattended_delivery ? 'mobile-icon' : 'land-icon'
                "
              />
            </Tooltip>
            <p class="ml-2">
              {{ $tc('contact.mobile') }}: {{ delivery.participant_mobile_number }}
            </p>
          </div>
        </div>

        <Link
          v-if="isDelivery"
          class="changes-history-link"
          @click="onViewDeliveryHistory"
          :permission="Permission.viewHistory"
          underlined
        >
          {{ $t('global.viewChangesHistory') }}
        </Link>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  ConstraintDatesViewer,
  CopyTextToClipBoard,
  DeliveryStatusSignUI,
  Dropdown,
  EtaTiming,
  Link,
  MessageBadge,
  Tooltip,
  DeliveryIcons
} from '@/components';
import { sortBy, prop, is } from 'ramda';
import { Permissions } from '@/utils/Secure';
import { CopaymentTag } from '..';
import { ArrowPlusDown, ArrowPlusUp } from '../Icons';

export default {
  components: {
    ConstraintDatesViewer,
    CopyTextToClipBoard,
    DeliveryStatusSignUI,
    Dropdown,
    EtaTiming,
    Link,
    MessageBadge,
    Tooltip,
    DeliveryIcons,
    CopaymentTag,
    ArrowPlusDown,
    ArrowPlusUp
  },
  data() {
    return {
      fast: false,
      isOpen: false,
      status: {},
      late: false,
      loading: { undoAssign: false, dropdown: true, pickup: '' }
    };
  },
  computed: {
    isRemovePickupLoading() {
      return this.loadingRemovePickup && this.loadingRemovePickup === this.delivery.id;
    },
    indicatorCssColor() {
      const color = this.delivery?.color;
      return color ? `background-color:${this.delivery.color};` : '';
    },
    allowAddPickup() {
      return !this.disabled && !this.isPickup;
    },
    priority() {
      const isString = is(String);

      if (!isString(this.delivery.priority)) return;
      return this.delivery.priority;
    },
    classes() {
      const classes = [];
      if (this.isOpen) classes.push('is-open');
      if (this.delivery.delivery_start_time || this.delivery.delivery_end_time)
        classes.push('delivery-event-heading-time');

      return classes.join(' ');
    },
    isPickup() {
      return this.delivery.delivery_event_type_id === 2;
    },
    isCollect() {
      return this.delivery.delivery_event_type_id === 8;
    },
    isDelivery() {
      return this.delivery.delivery_event_type_id === 3 || this.isCollect;
    },
    optionsDropdown() {
      const eventStatusId = this.delivery.delivery_event_status_id;
      const sortByIndex = sortBy(prop('priority'));
      const rxShowOn = this.isCollect ? [] : [2];
      // prettier-ignore
      let options = [
        {
          value: this.$t('dispatch.updateDestination'), event: 'reScheduleDelivery' ,
          priority: 2, showOn: [1, 2, 3, 5, 8, 11, 12, 13, 14, 15, 17],
          permission: this.Permission.reSchedule
        },
        {
          value: this.$t('dispatch.downloadDeliveryReport'), event: 'downloadFile',
          priority: 4, showOnDisabled: true,
          permission: this.Permission.report
        },
        {
          value: this.$t('dispatch.transferRx.transferRx'),event: 'transferRx',
          priority: 2, showOn: [8,15],
          permission: this.Permission.TransferRx
        },
        {
          value: this.$t('dispatch.changeStatus'),event: 'changeStatus',
          priority: 1,
          permission: this.Permission.changeDeliveryStatus
        },
        {
          value: this.$t('schedule.editParticipantInfo'),event: 'editParticipant',
          priority: 1, showOn: [1, 12], showOnDisabled: true,
          permission: this.Permission.changeDeliveryStatus
        },
        {
          value: this.$t('schedule.rx'), event: 'whereIsMyRx',
          priority: 1, showOn: rxShowOn,
          permission: this.Permission.shareRX
        },
        { 
          value: this.$t('copayment.manage'), event: 'editCopayment', priority: 1,
          showOn: [1, 2, 3, 5, 8],
      },
        {
          value: this.$t('dispatch.cancelDelivery'), event: 'cancelDelivery',
          priority: 3, showOn: [1, 2, 3, 5, 8, 12, 13, 14, 15],
          permission: this.Permission.changeDeliveryStatus
        },
        {
          value: this.$t('schedule.manifest.undoAssign'), event: 'unassignDelivery',
          priority: 3, showOn: [1, 8, 12, 15],
          permission: this.Permission.changeDeliveryStatus
        }
      ];
      const filteredOptions = options.filter(({ hideOn, showOn, showOnDisabled }) => {
        if (this.disabled) {
          if (showOnDisabled) return true;
          else return false;
        } else {
          if (showOn?.length || hideOn?.length) {
            if (hideOn && hideOn.some((ho) => ho === eventStatusId)) return false;
            if (showOn) return showOn.some((so) => so === eventStatusId);
            else return true;
          } else {
            return true;
          }
        }
      });
      return sortByIndex(filteredOptions);
    },
    Permission() {
      return Permissions.Dispatch;
    }
  },
  methods: {
    async onAddPickup(position) {
      const delivery = this.delivery;
      const secuence = delivery.secuence + (position === 'above' ? 0 : 1);
      this.$emit('onAddPickup', { delivery, secuence, position });
    },
    async onRemovePickup() {
      this.$emit('onRemovePickup', this.delivery.id);
    },
    formatTime(dateTime) {
      if (!dateTime) return '-';
      const utcDate = this.$moment.utc(dateTime).toDate();
      return this.$moment(utcDate).format('hh:mm A');
    },
    emitStatusWithArgs(eventName) {
      this.$emit(eventName, {
        delivery: this.delivery,
        delivery_id: this.delivery.id,
        delivery_tracking_token: this.delivery.tracking_token || null
      });
    },
    emitDropdownEvent({ event }) {
      let { href } = this.$router.resolve({
        name: 'deliveryReport',
        params: { eventId: this.delivery.id }
      });
      switch (event) {
        case 'whereIsMyRx':
          this.emitStatusWithArgs(event);
          break;
        case 'downloadFile':
          window.open(href, '_blank');
          break;
        case 'reScheduleDelivery':
          this.$store.commit('dispatch/rescheduleDelivery', this.delivery);
          break;
        case 'editCopayment':
          this.$emit('editCopayment', this.delivery);
          break;
        case 'changeStatus':
          this.emitStatusWithArgs(event);
          break;
        default:
          this.$emit(event, this.delivery);
          break;
      }
    },
    onShowOnMap() {
      this.$store.dispatch('map/focusedDelivery', this.delivery);
    },
    onViewDeliveryHistory() {
      this.$store.commit('dispatch/deliveryHistory', {
        showModal: true,
        hasDeliveryData: true,
        ...this.delivery
      });
    }
  },
  watch: {
    loadingPickup(value) {
      if (value && this.delivery.id === value.id) {
        this.loading.pickup = value.position;
      } else {
        this.loading.pickup = null;
      }
    }
  },
  props: {
    delivery: { type: Object, required: true, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    loadingRemovePickup: { type: Number, required: false },
    loadingPickup: { type: Object, default: () => {} },
    showAddress: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.delivery-event
  width: 100%
  margin-bottom: 4px
  position: relative
  .removePickup
    opacity: 0
    transition: opacity .2s ease
    margin-right: -.4rem
    margin-left: .2rem
    padding-left: .2rem
    border-left: 1px dashed $gray-100
    &.isLoading
      opacity: 1!important
  .pickup-loading
    height: 31px
  .cdp-indicator
    position: absolute
    background: #3f51b5
    width: 7px
    top: 10px
    bottom: 10px
    left: 2px
    border-radius: $br-sm
  &:hover
    .removePickup, .show-on-map
      opacity: 1
  .delivery-event-heading
    &:hover
      .add-above, .add-bellow
        opacity: 1
  .eta
    font-weight: bold
    margin-right: auto
  .delivery-event-heading
    background: $main-background
    transition: $transition-dark-mode
    border-radius: 4px
    .small
      font-size: $f-xxs
      .eta-timing
        font-weight: bold
      ::v-deep
        i:before
          font-size: $f-md
    &.is-open
      border-radius: 4px 4px 0px 0px
    .order-number ::v-deep a
        margin: 0
    .header-container
      line-height: 14px
    .title-container
      align-items: center
      .priority-dot
        line-height: -10px
        margin-right: 2.5px
      ::v-deep
        .refrigerated-icon
          margin-right: 2.5px
    .bold
      font-weight: bold
    .title-type
      font-size: 14px
      text-transform: uppercase
      font-weight: 500
    .icons-deliverys
      ::v-deep
        .simple-delivery .icon
          i:before
            transform: translate(-50%, -17px) !important
        .simple-delivery__collect .icon,  .simple-delivery__refrigerated .icon
          i:before
            transform: translate(-50%, -12px) !important
    .title-address,
    .title-cdp
      line-height: 16px
      font-weight: bold
      font-size: $f-xs
      color: $gray-800
      .upDownBoxIcon
        min-width: 24px
        width: 24px
      ::v-deep
        .icon-18
          height: 18px
          &::before
            font-size: 18px
    .title-cdp
      padding: 0
      border-radius: $br-sm
      ::v-deep
        .icon-sm
          color: $secondary

    .delivery-status-sign
      ::v-deep
        .CustomIcon
          margin-right: 0
        .icon-mixed
          width: 30px!important
          height: 30px!important
          svg
            height: 30px
            width: 30px
          .icon
            top: 0px
            font-size: 11px
          .ci-number
            font-size: 11px
            font-weight: bold
            top: 5px
  .delivery-event-heading-time
    background: $yellow-100-alert
    color: $yellow-900
  .delivery-event-collapse
    background: $main-background
    transition: $transition-dark-mode
    border-radius: 0px 0px 4px 4px
    .delivery-event-body
      padding: 0 20px 20px 20px
      .alert-advice
        background: $yellow-100-alert !important
        margin-bottom: 0.5rem
        align-content: center
        background-color: $yellow-900
        border-left: 4px solid $yellow-900
        border-radius: 0px 4px 4px 0px
        color: $yellow-900
        display: flex
        font-size: 16px
        font-weight: 400
        max-width: 267px
        padding: 0.563rem
        .icon
          margin-right: 0.25rem
          display: flex
          align-content: center
      .specific-delivery-time
        background: $yellow-100
        border-left: 4px solid $yellow-900
        border-radius: 4px 4px 4px 4px
        height: 40px
        line-height: 40px
        margin: auto
        margin-bottom: 10px
        p, .clock-alert
          background: $yellow-100
          color: $yellow-900
          display: inline-block
        .clock-alert
          margin-left: 8px
          margin-right: 8px
      .body-header
        margin-bottom: 0.4rem
      .changes-history-link
        margin-top: 1rem
        font-weight: bold
        text-decoration: none !important
  .phone-info
    .land-icon
      color: $blue-600
    .mobile-icon
      color: $green-600
  .add-above,
  .add-bellow,
  .show-on-map
    transition: opacity .2s ease
    opacity: 0
  .show-on-map
    width: 25px
    text-align: center
</style>
<style lang="sass">
.dark
  .delivery-event
    .removePickup
      border-color: $gray-700
  .delivery-event-body
    .message-badge .message-content div
      strong
        color: $main-background !important
  .delivery-event-heading
    background: $dark-500!important
  .delivery-event-collapse
    background: $gray-800!important
  .delivery-event-heading-time
    background: $yellow-900!important
    color: $main-background !important
  .delivery-event-heading-danger
    background: $red-900!important
    color: $main-background !important
  .phone-info
    .land-icon
      color: $blue-400
    .mobile-icon
      color: $green-500
  .delivery-event-heading
    .title-address
      color: $main-background !important
</style>
